import React from 'react'
import Cases from '../components/Cases'

const CaseStudies = () => {
  return (
    <>
    <section className='main'>
      <div className='container'>
        <div className='row'>
          <h1>
            <div className='line'>
              <span>Кейсы</span>
            </div>
          </h1>
          <div className='btn-row'>
            <a className='hover:bg-yellow-400 bg-yellow-300 py-2 px-6 rounded-xl' href='/'>
              Обсудить проект
            </a>
          </div>
        </div>
      </div>
    </section>
    <Cases />
    </>
  )
}

export default CaseStudies
